// content actions
export const GROUP = "GROUP";
export const SUBJECT = "SUBJECT";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const COURSE = "COURSE";
export const CHAPTER = "CHAPTER";
export const TOPIC = "TOPIC";
export const CYCLE = "CYCLE";
export const QUIZ = "QUIZ";
export const SEMESTER = "SEMESTER";
export const SECTION = "SECTION";
export const SIMULATION = "SIMULATION";
export const PAPER = "PAPER";

// profile actions
export const PROFILE = "PROFILE";

// storage actions
export const RESPONSE = "RESPONSE";
export const TEMP = "TEMP";
export const EXAMDATA = "EXAMDATA";
export const NOTIFICATION = "NOTIFICATION";
export const STUDY_GUIDE = "STUDY_GUIDE";

// application state actions
export const EXAM_STATE = "EXAM_STATE";
export const CART_COUNT = "CART_COUNT";
export const ACTIVE_STATE = "ACTIVE_STATE";

// reset actions
export const RESET_STATE = "RESET_STATE";
